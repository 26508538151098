// @flow
import * as React from 'react'
import styled from 'styled-components'
import { Container, Row, config, Col } from 'react-awesome-styled-grid'
import { Navigation, Pagination, A11y, EffectFade, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import type {
  HomePageSpotLightType,
  HomePageSpotLightItemType
} from '../../services/contentfulPosts'
import { P, colors, H250, P18, GoodSansB3, GoodSansH2, QueensH4 } from '../shared'
import { OutlineOnFocusVisible, CTASecondaryHyperlink } from '../shared/buttons'
import { LeftSliderArrow } from '../shared/icons/LeftSliderArrow'
import { RightSliderArrow } from '../shared/icons/RightSliderArrow'
import { FadeInImage } from '@components/shared/FadeInImage'
import { isChromeForIOs145 } from '@helpers/utils'
import { useSearchParams } from 'next/navigation'

const breakpoints = {
  // $FlowFixMe
  0: {
    slidesPerView: 1.15,
    spaceBetween: 10
  },
  // $FlowFixMe
  480: {
    slidesPerView: 1.5
  },
  // $FlowFixMe
  768: {
    slidesPerView: 2.5,
    spaceBetween: 20
  },
  // $FlowFixMe
  1024: {
    slidesPerView: 3.15,
    spaceBetween: 33
  }
}

export default function Spotlight (props: {
  spotlightInfo: HomePageSpotLightType,
  showInline?: boolean
}): React.Node {
  const searchParams = useSearchParams()
  if (searchParams.get('blog')) {
    return null
  }
  const length = props.spotlightInfo.spotlights.length
  if (props.showInline && length > 2) {
    return (
      <InlineSection>
        <InlineSectionHeader>
          <InlineHeader>{props.spotlightInfo.sectionTitle}</InlineHeader>
        </InlineSectionHeader>
        <Swiper
          grabCursor={true}
          modules={[Navigation, Pagination, A11y, EffectFade, Mousewheel]}
          pagination={{
            clickable: true,
            type: 'bullets'
          }}
          mousewheel={{ forceToAxis: true, sensitivity: 0.15 }}
          cssMode={isChromeForIOs145()}
          direction="horizontal"
          freeMode={false}
          slidesPerView={2}
          breakpoints={breakpoints}
        >
          {props.spotlightInfo.spotlights.map(
            (
              spotlight: HomePageSpotLightItemType,
              index: number
            ): React.Node => {
              return (
                <SwiperSlide key={`spotlight-slider-group-${index}`}>
                  <ImageContainer>
                    {spotlight && spotlight.image && <FadeInImage
                      src={spotlight.image.file.url}
                      alt={spotlight.image.title}
                      height={spotlight.image.file.details.image.height}
                      width={spotlight.image.file.details.image.width}
                    />}
                  </ImageContainer>
                  <Title>
                    {spotlight.title}
                  </Title>
                  <Body>
                    {spotlight.description}
                  </Body>
                  <SpotlightBtn
                    type="button"
                    role="button"
                    href={spotlight.link}
                    tabIndex='0'
                    aria-label={spotlight.cta || ''}
                    color={colors.sand}
                  >
                    {spotlight.cta}
                  </SpotlightBtn>
                </SwiperSlide>
              )
            }
          )}
        </Swiper>
      </InlineSection>
    )
  }
  return (
    <Section isSmall={props.showInline}>
      <Container>
        <Row>
          <Col
            xs={4}
            md={6}
            align="center"
            justify="center"
            offset={{
              md: 1
            }}
          >
            <MobileSlider className='mobile-slider'>
              <SpotlightHr />
              <SectionHeader>{props.spotlightInfo.sectionTitle}</SectionHeader>
              <Swiper
                modules={[Navigation, Pagination, A11y, EffectFade]}
                spaceBetween={0}
                slidesPerView={1}
                pagination={{
                  clickable: true,
                  type: 'bullets'
                }}
                cssMode={isChromeForIOs145()}
              >
                {props.spotlightInfo.spotlights.map(
                  (
                    spotlight: HomePageSpotLightItemType,
                    index: number
                  ): React.Node => {
                    return (
                      <SwiperSlide key={`spotlight-slider-${index}`}>
                        <MobileSlide>
                          <SpotlightImg>
                            {spotlight && spotlight.image && <FadeInImage
                              src={spotlight.image.file.url}
                              alt={spotlight.image.title}
                              height={spotlight.image.file.details.image.height}
                              width={spotlight.image.file.details.image.width}
                            />}
                          </SpotlightImg>

                          <H250
                            color={colors.sand}
                            styles={{ marginBottom: '15px' }}
                          >
                            {spotlight.title}
                          </H250>
                          <Description color={colors.sand}>
                            {spotlight.description}
                          </Description>
                          <SpotlightBtn
                            type="button"
                            role="button"
                            href={spotlight.link}
                            tabIndex='0'
                            aria-label={spotlight.cta || ''}
                            color={colors.sand}
                          >
                            {spotlight.cta}
                          </SpotlightBtn>
                        </MobileSlide>
                      </SwiperSlide>
                    )
                  }
                )}
              </Swiper>
            </MobileSlider>
          </Col>

          <Col xs={4} md={6} lg={12} align="center" justify="center">
            <DesktopSlider className='desktop-slider'>
              <DesktopNavigation>
                <SpotlightHr />
                <div>
                  <SectionHeader>
                    {props.spotlightInfo.sectionTitle}
                  </SectionHeader>
                  {length > 1 && (
                    <ArrowWrapper className='arrow-wrapper'>
                    <ArrowButton className={'previous slidePrev-btn-spotlight'}>
                      <LeftSliderArrow color={colors.sand} />
                    </ArrowButton>
                    <ArrowButton className={'slideNext-btn-spotlight'}>
                      <RightSliderArrow color={colors.sand} />
                    </ArrowButton>
                  </ArrowWrapper>
                  )}
                </div>
              </DesktopNavigation>

              <Swiper
                modules={[Navigation, Pagination, A11y, EffectFade, Mousewheel]}
                spaceBetween={0}
                slidesPerView={1}
                effect="fade"
                fadeEffect={{
                  crossFade: true
                }}
                pagination={{
                  clickable: true,
                  type: 'bullets'
                }}
                navigation={{
                  prevEl: '.slidePrev-btn-spotlight',
                  nextEl: '.slideNext-btn-spotlight'
                }}
                mousewheel={{ forceToAxis: true, sensitivity: 0.15 }}
              >
                {props.spotlightInfo.spotlights.map(
                  (
                    spotlight: HomePageSpotLightItemType,
                    index: number
                  ): React.Node => {
                    return (
                      <SwiperSlide key={`spotlight-slider-${index}`}>
                        <DesktopView>
                          <SpotlightImg>
                            {spotlight && spotlight.image && <FadeInImage
                              src={spotlight.image.file.url}
                              alt={spotlight.image.title}
                              height={700}
                              width={700}
                              styles={{ width: 'unset' }}
                            />}
                          </SpotlightImg>
                          <AlignBottomDiv>
                            <div>
                              <H250
                                color={colors.sand}
                                styles={{ marginBottom: '15px' }}
                              >
                                {spotlight.title}
                              </H250>
                              <Description
                                className='description'
                                color={colors.sand}
                                styles={{ marginBottom: '25px' }}
                              >
                                {spotlight.description}
                              </Description>
                              <SpotlightBtn
                                type="button"
                                target="_blank"
                                role="button"
                                href={spotlight.link}
                                tabIndex='0'
                                aria-label={spotlight.cta || ''}
                                color={colors.sand}
                              >
                                {spotlight.cta}
                              </SpotlightBtn>
                            </div>
                          </AlignBottomDiv>
                        </DesktopView>
                      </SwiperSlide>
                    )
                  }
                )}
              </Swiper>
            </DesktopSlider>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

const Section = styled.div`
  background-color: ${colors.midnightBlue};
  padding: 50px 0 65px 0;
   ${(props: any): string => config(props).media.md`
      padding: 50px 0;
  `}
  .swiper-pagination {
    padding: 0 23px 10px 0 !important;
    ${(props: any): string => config(props).media.lg`
      padding: 0 0 32px 0 !important;
    `}
  }
  .swiper-pagination-bullet {
    height: 11px;
    width: 11px;
    margin: 0 6px !important;
    background-color: transparent !important;
    border: 1px solid ${colors.sand} !important;
    opacity: 1 !important;
    ${OutlineOnFocusVisible};
  }
  .swiper-pagination-bullet-active {
    background-color: ${colors.sand} !important;
    border: 1px solid ${colors.sand} !important;
  }

  ${(props: any): ?string => {
    if (props.isSmall) {
      return `
      margin: 60px 0;
      .mobile-slider {
        max-width: 500px;
      }
      .desktop-slider {
        max-width: 1000px;
      }
      h2 {
        ${QueensH4};
        color: ${colors.sand};
        margin-bottom: 15px;
      }
      p.description {
        ${GoodSansB3};
        color: ${colors.sand};
        margin-bottom: 15px;
      }
      `
    }
  }}
`
const Description = styled(P18)`
  font-size: 16px;
  line-height: 1.5em;
  margin: 0 0 25px;
  ${(props: any): string => config(props).media.lg`
    font-size: 18px;
  `}
`
const MobileSlider = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;
  .swiper-pagination-border {
    border: 1px solid ${colors.sand};
  }
  .swiper-pagination {
    padding: 0 !important;
    bottom: 0 !important;
  }
  ${(props: any): string => config(props).media.lg`
    display: none;
  `}
`
const DesktopSlider = styled.div`
  display: none;
  ${(props: any): string => config(props).media.lg`
    max-width: 1300px;
    width: 100%;
    margin: 0 30px;
    display: flex;
    overflow: hidden;
    position: relative;
    .swiper-pagination-border {
      border: 1px solid ${colors.sand};
      background: ${colors.sand};
    }
    .swiper-pagination {
      box-sizing: border-box;
      bottom: 0 !important;
      text-align: right;
      padding-right: 20px;
    }
  `}
`
const SpotlightHr = styled.hr`
  height: 1px;
  background-color: ${colors.sand};
  border: none;

  margin: 16px 0 8px 0;
  ${(props: any): string => config(props).media.sm`
     margin: 16px 12px 8px 10px;
  `}
   ${(props: any): string => config(props).media.lg`
     margin: 16px 0 8px 0;
  `}
`
const SectionHeader = styled(P)`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  font-weight: bold;
  color: ${colors.sand};
  text-transform: uppercase;
  margin: 25px 0 32px 0;
  ${(props: any): string => config(props).media.sm`
    margin: 25px 0 34px 16px;
  `}
  ${(props: any): string => config(props).media.lg`
    margin: 25px 0 0 0;
  `}
`

const InlineHeader = styled(SectionHeader)`
margin: 25px 0 32px 0;
  ${(props: any): string => config(props).media.sm`
    margin: 25px 0 34px 0;
  `}
  ${(props: any): string => config(props).media.lg`
    margin: 25px 0 25px 0;
  `}
`
const MobileSlide = styled.div`
  ${(props: any): string => config(props).media.sm`
      margin: 0 15px 0px;
  `}
`
const SpotlightImg = styled.div`
  margin: 0 0 20px 0;
  display: flex;
  justify-content: flex-end;

  .fade-in-image {
    border-radius: 42px;
  }

`
const SpotlightBtn = styled(CTASecondaryHyperlink)`
  display: inline-block;
  margin-bottom: 25px;
  ${(props: any): string => config(props).media.lg`
    margin-bottom: 45px;
  `}
`
const DesktopView = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > * {
    box-sizing: border-box;
    width: calc(50% - 20px);
  }
`
const DesktopNavigation = styled.div`
  box-sizing: border-box;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(50% - 20px);
  z-index: 2;
  > div {
    display: flex;
    flex-direciton: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`
const AlignBottomDiv = styled.div`
  margin-top: 100px;
  align-self: flex-end;
`
const ArrowWrapper = styled.div`
  display: flex;
  flex-direciton: row;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 2px 0 0;
`
const ArrowButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 52px;
  width: 67px;
  background: none;
  border: none;
  opacity: 1;
  outline: none;
  border-radius: 10px;
  ${OutlineOnFocusVisible};
  svg {
    width: 100%;
    height: 100%;
  }
  &.previous {
    margin-right: 36px;
  }
  :hover,
  &.swiper-button-disabled {
    opacity: 0.5;
  }
`
const InlineSection = styled.main`
  padding: 50px 0;
   ${(props: any): string => config(props).media.lg`
      padding: 80px 0;
  `}
  .swiper-slide {
    max-width: 450px;
    padding-bottom: 40px;
  }
  .swiper-pagination-bullet {
    height: 11px;
    width: 11px;
    margin: 0 6px !important;
    bottom: 0px !important;
    background-color: transparent !important;
    border: 1px solid ${colors.sand} !important;
    opacity: 1 !important;
    ${OutlineOnFocusVisible};
  }
  .swiper-pagination-bullet-active {
    background-color: ${colors.sand} !important;
    border: 1px solid ${colors.sand} !important;
  }
`
const InlineSectionHeader = styled.div`
  border-top: 1px solid ${colors.sand};
`
const ImageContainer = styled.div`
  border-radius: 20px;
  margin-bottom: 40px;
  overflow: hidden;
  background: ${colors.midnightBlueTint}
`
const Body = styled.p`
  ${GoodSansB3};
  color: ${colors.sand};
  margin-bottom: 1.5rem;
`
const Title = styled.p`
  ${GoodSansH2};
  color: ${colors.sand};
  margin-bottom: .8rem;
`
