// @flow
import * as React from 'react'
import type { TrackPropsType } from '../../../models/Track'
import { getAllPopularTracksProps } from '../../../models/Track'
import { FadeInImage } from '@components/shared/FadeInImage'
import PlayIcon from '@components/shared/icons/PlayIcon'
import styled from 'styled-components'
import { config } from 'react-awesome-styled-grid'
import { colors, GoodSansB5 } from '@components/shared'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { HeaderSeeAll } from '@components/shared/HeaderSeeAll'

type PopularTracksRowPropsType = {
  themeId?: string,
  title?: string,
  description?: string
}
export function PopularTracksRow (props: PopularTracksRowPropsType): React.Node {
  const router = useRouter()
  const [popularTracks, setPopularTracks] = React.useState<TrackPropsType[] | null>(null)
  React.useEffect((): void => {
    getAllPopularTracksProps(props.themeId).then((res: TrackPropsType[]): void => {
      setPopularTracks(res.slice(0, 12))
    })
  }, [props.themeId])
  if (!popularTracks) return null
  const cta = {
    title: 'SEE ALL',
    url: '/stories/popular'
  }
  return (
    <PopularContainer>
      <HeaderSeeAll title={props.title || 'Popular on Dipsea'} cta={cta} description={props.description}/>
      <PopularTracksContainer>
        <PopularTracksGridList>
          {popularTracks.map((track: TrackPropsType, index: number): React.Node => {
            const title = track.title
            return (
              <PopularTracksListItem key={`popular-${index}`} onClick={(): void => router.push(track.slug)}>
                <PopularInfoAndImageContainer>
                  <ImageContainer><FadeInImage src={track.images.img500} height={500} width={500}/></ImageContainer>
                  <ItemInfoContainer>
                    <ItemTitle><Link href={track.slug} passHref={true}>{title}</Link></ItemTitle>
                    <ItemPopularity>{track.popularity && track.popularity.toLocaleString()} plays</ItemPopularity>
                  </ItemInfoContainer>
                </PopularInfoAndImageContainer>
                <IconContainer>
                  <PlayIcon size={24}/>
                </IconContainer>
              </PopularTracksListItem>
            )
          })}
        </PopularTracksGridList>
      </PopularTracksContainer>
    </PopularContainer>
  )
}

const PopularInfoAndImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IconContainer = styled.div`
  display: flex;
`
const ItemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`

const ImageContainer = styled.div`
  height: 50px;
  width: 50px;
  flex-shrink: 0;
  img {
      border-radius: 8px;
  }
  ${(props: any): string => config(props).media.md`
    height: 67px;
    width: 67px;
  `}
`

const ItemTitle = styled.div`
  ${GoodSansB5};
  a {
    color: ${colors.white};
    font-family: "Good Sans Bold";
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    text-decoration: none;
    letter-spacing: -0.3px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    ${(props: any): string => config(props).media.lg`
      font-size: 15px;
      letter-spacing: 0px;
    `}
  }
`

const ItemPopularity = styled.div`
  font-family: "Good Sans Regular";
  color: ${colors.white70};
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.3px;
  margin-top: 4px;
  ${(props: any): string => config(props).media.md`
    font-size: 14px;
  `}
`

const PopularTracksListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px 10px 0px;
  min-width: 312px;
  border-bottom: 0.5px solid ${colors.white25};
`
const PopularTracksGridList = styled.div`
  display: grid;
  margin: 0;
  padding: 0;
  width: 312px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0px 16px;
  ${(props: any): string => config(props).media.md`
    grid-template-columns: auto auto auto;
    width: 100%;
  `}
  ${(props: any): string => config(props).media.lg`
    grid-gap: 0px 40px;
  `}
`
const PopularTracksContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
`

const PopularContainer = styled.div`
  width: 100%;
  margin-top: 25px;
  ${(props: any): string => config(props).media.xs`
    margin-top: 50px;
  `}
  ${(props: any): string => config(props).media.md`
    margin-top: 60px;
  `}
`
